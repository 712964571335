import media from 'globalStyles/utils/media';
import styled from 'styled-components/macro';

export const FooterContainer = styled.div`
  padding: 70px;
  background: white;
  width: 100vw;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    margin-top: 12px;
    color: white;
    width: 380px;
  }

  ${media.tablet`
    flex-direction: column;
    padding: 32px 24px;
  `}
`;

export const LowerMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #737373;
  padding-bottom: 48px;

  ${media.tablet`
    flex-direction: column;
  `}
`;

export const MessageAreaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 48px;

  a,
  p {
    text-decoration: none;
    color: #808080;
    font-size: 18.001px;
    font-style: normal;
    font-weight: 400;
    text-align: center;

    ${media.tablet`
    font-size: 14.001px;
    display: flex;
    justify-content: center;
  `}
  }

  h4 {
    color: #808080;
    font-size: 18.001px;
    font-style: normal;
    font-weight: 400;

    ${media.tablet`
    font-size: 14.001px;
		margin-top: 12px;
  `}
  }

  span {
    font-weight: 600;
  }

  ${media.tablet`
    flex-direction: column;
		align-items: center;
		padding-top: 24px;
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;

  img {
    margin-right: 8px;
  }
`;

export const FooterMenu = styled.div`
  display: flex;

  h3 {
    margin-right: 24px;
    color: #2e2e2e;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;

    ${media.tablet`
    font-size: 14px !important;
  `}
  }

  ${media.tablet`
    margin-top: 24px;
  `}
`;

export const FooterIcons = styled.div`
  display: flex;

	img{
		margin-right: 8px;
	}

  ${media.tablet`
	margin-top: 32px;
  `}
`;
