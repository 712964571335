import React from "react";
import { Container, Logo, SideItems, Item, Paragraph } from "./style";
import LogoImage from "assets/svgs/whiteLogo.svg";
import { Menu } from "pages/home/header/menu";
import HeaderHelper from "pages/home/header/helpers";

const SideMenu = ({ open, setOpen }) => {
  const helper = new HeaderHelper()
  return(
    <Container open={open}>
      <Logo>
        <div>
          <img src={LogoImage} alt="" />
          <h1>TriggerApp</h1>
        </div>
        <p onClick={()=>helper.toggleSideMenu(open, setOpen)} >x</p>
      </Logo>
      <SideItems>
        {
          Menu.map((menuItem, i)=>(
            <Item key={i}>
              {/* <img src={menuItem.image} alt="" /> */}
              <Paragraph onClick={()=>helper.sideMenuScroll(menuItem.id, setOpen)}>{menuItem.label}</Paragraph>
            </Item>
          ))
        }
      </SideItems>
    </Container>
)
};

export default SideMenu;
