export default class HeaderHelper {
  toggleSideMenu(menu, setMenu) {
    setMenu(!menu);
  }

  scrollToMenu(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }

  sideMenuScroll(id, setMenu) {
    setMenu(false);
    this.scrollToMenu(id);
  }
}
