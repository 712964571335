import React from 'react';
import {
  AboutBottom,
  AboutContainer,
  AboutHeadGradient,
  AboutOption,
  AboutWordsArea,
  LineDiv,
  MobileAboutBottom,
  // MobileAboutHeadGradient,
  MobileTextArea,
} from './style';
import Header from '../header/Header';
import { Span } from 'globalStyles/triggerStyle/style';
import { ICON1, ICON2, ICON3, ICON4 } from 'assets/assets';
import ScrollAnimation from 'components/scrollAnimation';

const aboutOptions = [
  {
    title: 'Emergency Contact',
    icon: ICON1,
    showCircle: false,
  },
  {
    title: 'Panic Button',
    icon: ICON2,
    showCircle: false,
  },
  {
    title: 'Check In',
    icon: ICON3,
    showCircle: false,
  },
  {
    title: 'Activity Timeline',
    icon: ICON4,
    showCircle: false,
  },
];

export default function About() {
  return (
    <AboutContainer id="about">
      <Header />
      <AboutHeadGradient>
        <AboutWordsArea>
          <ScrollAnimation>
            <h1>
              <Span>Safety</Span> Together!
            </h1>
          </ScrollAnimation>
          <div>
            <LineDiv />
            <ScrollAnimation>
              <p>
                Peer to peer security network that helps you simultaneously and
                instantly share vital information with everyone that matters during
                an emergency.
              </p>
            </ScrollAnimation>
          </div>
        </AboutWordsArea>
      </AboutHeadGradient>
      <AboutBottom>
        {aboutOptions.map((option, i) => (
          <ScrollAnimation key={i}>
            <AboutOption>
              <img src={option.icon} alt="icon-" />
              <h3>{option.title}</h3>
            </AboutOption>
          </ScrollAnimation>
        ))}
      </AboutBottom>
      <MobileAboutBottom>
        {/* <MobileAboutHeadGradient /> */}
        <AboutWordsArea>
          <ScrollAnimation>
            <h1>
              <Span>Safety</Span> Together!
            </h1>
          </ScrollAnimation>
          <ScrollAnimation>
            <div>
              <LineDiv />
              <p>
                Peer to peer <Span>security network</Span> that helps you
                simultaneously and instantly share vital information with everyone
                that matters during an emergency.
              </p>
            </div>
          </ScrollAnimation>
        </AboutWordsArea>
        <MobileTextArea>
          <ScrollAnimation>
            {/* <a href={appUrl} target="_blank" rel="noreferrer">
              <button>Sign Up</button>
            </a> */}
            <h2>
              <Span>TriggerApp</Span> Features
            </h2>
          </ScrollAnimation>
        </MobileTextArea>
        {aboutOptions.map((option, i) => (
          <AboutOption key={i}>
            <ScrollAnimation>
              <img src={option.icon} alt="icon-" />
            </ScrollAnimation>
            <ScrollAnimation>
              <h3>{option.title}</h3>
            </ScrollAnimation>
          </AboutOption>
        ))}
      </MobileAboutBottom>
    </AboutContainer>
  );
}
