/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export default function AuthProvider({ children }) {
	const obj = {
		makingRequest: false,
    showCongrats: false,
		waitingList: {
			email: "",
			username: "",
      phone: "",
			password: "",
			confirmPassword: "",
		},
	};

	const [authObj, setAuthObj] = useState(obj);

	return (
		<AuthContext.Provider value={{ authObj, setAuthObj }}>
			{children}
		</AuthContext.Provider>
	);
}

export const useAuthContext = () => useContext(AuthContext);
