import React, { createContext, useContext, useState } from "react";

const ResponseContext = createContext();

export default function ResponseProvider({ children }) {

  const obj = {
    isOpen: false,
    isError: false,
    heading: '',
    messages: []
  }
  
  const [responseObj, setResponseObj] = useState(obj)

  return (
    <ResponseContext.Provider value={{responseObj, setResponseObj}}>
      {children}
    </ResponseContext.Provider>
  );
}

export const useResponseContext= ()=> useContext(ResponseContext)
