import React from 'react';
import {
  BouncingImage,
  FeatureList,
  FeaturesFlex,
  FeaturesHeader,
  IconHolder,
  PhonesArea,
  TextArea,
} from './style';
// import PanicFeature from './PanicFeature';
// import ThreatMap from './ThreatMap';
// import ActivityFeature from './ActivityFeature';
import { Span } from 'globalStyles/triggerStyle/style';
import {
  ACTIVITYICON,
  ACTIVITYIMG1,
  ACTIVITYIMG2,
  ACTIVITYIMG3,
  CHECKINICON,
  CHECKINIMG1,
  CHECKINIMG2,
  CHECKINIMG3,
  EMERGENCYICON,
  EMERGENCYIMG1,
  EMERGENCYIMG2,
  EMERGENCYIMG3,
  PANICICON,
  PANICIMG1,
  PANICIMG2,
  PANICIMG3,
} from 'assets/assets';
import ScrollAnimation from 'components/scrollAnimation';
// import { FeatureList } from './style';

export default function Features() {
  return (
    <FeatureList id="features">
      <FeaturesHeader>
        <ScrollAnimation>
          <h1>
            How can <Span>TriggerApp</Span> Give You Optimal Security?
          </h1>
        </ScrollAnimation>
        <ScrollAnimation>
          <p>
            Explore the features of TriggerApp, from adding an emergency contact to
            how panic button, check-in, and activity timeline works.
          </p>
        </ScrollAnimation>
      </FeaturesHeader>
      <FeaturesFlex>
        <TextArea>
          <ScrollAnimation>
            <IconHolder>
              <img src={EMERGENCYICON} alt="icon" />
            </IconHolder>
          </ScrollAnimation>
          <ScrollAnimation>
            <h1>Add Emergency Contact</h1>
          </ScrollAnimation>
          <ScrollAnimation>
            <p>
              Create your unique account, share and add everyone that matters to you.
              Works across every device. iOs and Android! <br /> <br /> Personalize
              your own community further by creating specific circles
            </p>
          </ScrollAnimation>
        </TextArea>
        <PhonesArea bg="#EFEFEF">
          <BouncingImage bounceDelay={4} src={EMERGENCYIMG1} alt="emer" />
          <BouncingImage bounceDelay={6} src={EMERGENCYIMG2} alt="emer" />
          <BouncingImage bounceDelay={8} src={EMERGENCYIMG3} alt="emer" />
        </PhonesArea>
      </FeaturesFlex>
      <FeaturesFlex>
        <PhonesArea bg="#60B7E2">
          <BouncingImage bounceDelay={4} src={PANICIMG1} alt="emer" />
          <BouncingImage bounceDelay={6} src={PANICIMG2} alt="emer" />
          <BouncingImage bounceDelay={8} src={PANICIMG3} alt="emer" />
        </PhonesArea>
        <TextArea>
          <ScrollAnimation>
            <IconHolder>
              <img src={PANICICON} alt="icon" />
            </IconHolder>
          </ScrollAnimation>
          <ScrollAnimation>
            <h1>Panic Button</h1>
            <p>
              Simultaneously send emergency alerts to your contacts and everyone
              receives your live location and live audio at the same time. With a
              single tap... You’ll also get instant alerts when your friends and
              loved ones raise an alarm
            </p>
          </ScrollAnimation>
        </TextArea>
      </FeaturesFlex>
      <FeaturesFlex>
        <TextArea>
          <ScrollAnimation>
            <IconHolder>
              <img src={CHECKINICON} alt="icon" />
            </IconHolder>
          </ScrollAnimation>
          <ScrollAnimation>
            <h1>Check-In</h1>
            <p>
              Going out? Or saying goodbye to someone?  Be instantly notified if your
              loved one doesn’t arrive where they are supposed to, when they are
              supposed to.
            </p>
          </ScrollAnimation>
        </TextArea>
        <PhonesArea bg="#EFEFEF">
          <BouncingImage bounceDelay={4} src={CHECKINIMG1} alt="emer" />
          <BouncingImage bounceDelay={6} src={CHECKINIMG2} alt="emer" />
          <BouncingImage bounceDelay={8} src={CHECKINIMG3} alt="emer" />
        </PhonesArea>
      </FeaturesFlex>
      <FeaturesFlex>
        <PhonesArea bg="#60B7E2">
          <BouncingImage bounceDelay={4} src={ACTIVITYIMG1} alt="emer" />
          <BouncingImage bounceDelay={6} src={ACTIVITYIMG2} alt="emer" />
          <BouncingImage bounceDelay={8} src={ACTIVITYIMG3} alt="emer" />
        </PhonesArea>
        <TextArea>
          <ScrollAnimation>
            <IconHolder>
              <img src={ACTIVITYICON} alt="icon" />
            </IconHolder>
          </ScrollAnimation>
          <ScrollAnimation>
            <h1>Activity Timeline</h1>
            <p>
              Give your contacts and community a heads-up on what’s happening around
              you, and be the first to get firsthand information from reliable
              sources in your circle/community. <br /> Share safety updates so
              everyone is aware and can avoid unsafe situations.
            </p>
          </ScrollAnimation>
        </TextArea>
      </FeaturesFlex>
      {/* <PanicFeature />
      <ThreatMap />
      <ActivityFeature /> */}
    </FeatureList>
  );
}
