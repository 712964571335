import React from 'react';
import {
  HeaderTextDiv,
  ImageArea,
  TextArea,
  WhyHeader,
  WhyTriggerContainer,
} from './style';
import { WHYTRIGGERBPHONES } from 'assets/assets';
import ScrollAnimation from 'components/scrollAnimation';

export default function WhyTrigger() {
  return (
    <WhyTriggerContainer id="why-trigger">
      <WhyHeader>
        <ScrollAnimation>
          <HeaderTextDiv>
            <span>TriggerApp</span> Helps Keep Your Family Secure!
          </HeaderTextDiv>
        </ScrollAnimation>
        <ScrollAnimation>
          <TextArea>
            TriggerApp notifies your family instantly through panic buttons or
            distress messages, ensuring swift response in emergencies. Enhances
            safety by sharing the user's location with family, aiding quick
            coordination and intervention in urgent situations. Allows for private
            distress messages and panic button use, addressing concerns about time
            and secrecy during crises.
          </TextArea>
        </ScrollAnimation>
      </WhyHeader>
      <ScrollAnimation>
        <ImageArea>
          <img src={WHYTRIGGERBPHONES} alt="phones" />
        </ImageArea>
      </ScrollAnimation>
    </WhyTriggerContainer>
  );
}
