import React from 'react';
import {
  FooterContainer,
  FooterIcons,
  FooterMenu,
  LogoWrapper,
  LowerMenuContainer,
  MessageAreaContainer,
} from './style';
import { LOGO3 } from 'assets/assets';
import { Menu } from '../header/menu';
import HeaderHelper from '../header/helpers';
import { contactUs } from './footerData';
import ScrollAnimation from 'components/scrollAnimation';

function LowerMenu() {
  const helper = new HeaderHelper();

  return (
    <LowerMenuContainer>
      <LogoWrapper>
        <img src={LOGO3} alt="logo-" />
        <h3>TriggerApp</h3>
      </LogoWrapper>
      <FooterMenu>
        {Menu.map((item, i) => (
          <h3 onClick={() => helper.scrollToMenu(item.id)} key={i}>
            {item.label}
          </h3>
        ))}
      </FooterMenu>
      <FooterIcons>
        <a href={contactUs.facebookLink} target="_blank" rel="noreferrer">
          <img src={contactUs.facebookIcon} alt="icon" />
        </a>
        <a href={contactUs.twitterLink} target="_blank" rel="noreferrer">
          <img src={contactUs.twitterIcon} alt="icon" />
        </a>
        <a href={contactUs.instagramLink} target="_blank" rel="noreferrer">
          <img src={contactUs.instagramIcon} alt="icon" />
        </a>
      </FooterIcons>
    </LowerMenuContainer>
  );
}

function MessageArea() {
  const date = new Date().getFullYear();
  return (
    <MessageAreaContainer>
      <a href={`mailto: ${contactUs.email}`}>
        <p>{contactUs.email}</p>
      </a>
      <h4>
        <span> &copy; {date}</span> TriggerApp. All Rights Reserved
      </h4>
    </MessageAreaContainer>
  );
}

export default function Footer() {
  return (
    <FooterContainer>
      <ScrollAnimation>
        <LowerMenu />
      </ScrollAnimation>
      <ScrollAnimation>
        <MessageArea />
      </ScrollAnimation>
    </FooterContainer>
  );
}
