import React from "react";
import AppProvider from "./AppContext.jsx";
import AuthProvider from "./AuthContext.jsx";
import ResponseProvider from "./ResponseContext.jsx";

function Store({ children }) {
  return (
    <AppProvider>
      <AuthProvider>
        <ResponseProvider>
          {children}
        </ResponseProvider>
      </AuthProvider>
    </AppProvider>
  );
}

export default Store;
