import React from 'react';
import {
  FailedContainer,
  InformationContainer,
  LinkArea,
  MainWarningContainer,
  SuccessContainer,
  ToastHead,
  WarningHead,
  WrapperHolder,
} from './style';
import forward from 'assets/svgs/forward.svg';
import useToast from 'hooks/useToast';
export default function Toast() {
  const { toast } = useToast();
  const { isOpen, heading, link, linkText, messages, isError, toastType } = toast;
  const isInformation = toastType === 'information';
  const isWarning = toastType === 'warning';
  const isSuccess = toastType === 'success';
  const isFailed = toastType === 'failed';

  if (isInformation) {
    return (
      <WrapperHolder isError={isError} show={isOpen ? 'block' : 'none'}>
        <InformationContainer isError={isError} show={isOpen ? 'block' : 'none'}>
          {heading && (
            <WarningHead>
              <ToastHead>{heading}</ToastHead>
              <h2 onClick={() => toast.closeResponse()}>x</h2>
            </WarningHead>
          )}
          {messages.map((message, i) => (
            <div key={i}>
              <h5>{message}</h5>
              <br />
            </div>
          ))}
          {linkText && (
            <LinkArea>
              <a href={link}>
                <h4>{linkText}</h4>
              </a>
              <img src={forward} alt="arrow" />
            </LinkArea>
          )}
        </InformationContainer>
      </WrapperHolder>
    );
  } else if (isWarning) {
    return (
      <WrapperHolder isError={isError} show={isOpen ? 'block' : 'none'}>
        <MainWarningContainer isError={isError} show={isOpen ? 'block' : 'none'}>
          <WarningHead>
            <ToastHead>{heading}</ToastHead>
            <h2 onClick={() => toast.closeResponse()}>x</h2>
          </WarningHead>
          {messages.map((message, i) => (
            <div key={i}>
              <h5>{message}</h5>
              <br />
            </div>
          ))}
        </MainWarningContainer>
      </WrapperHolder>
    );
  } else if (isSuccess) {
    return (
      <WrapperHolder isError={isError} show={isOpen ? 'block' : 'none'}>
        <SuccessContainer isError={isError} show={isOpen ? 'block' : 'none'}>
          <WarningHead>
            <ToastHead>{heading}</ToastHead>
            <h2 onClick={() => toast.closeResponse()}>x</h2>
          </WarningHead>
          {messages.map((message, i) => (
            <div key={i}>
              <h5>{message}</h5>
              <br />
            </div>
          ))}
        </SuccessContainer>
      </WrapperHolder>
    );
  } else if (isFailed) {
    return (
      <WrapperHolder isError={isError} show={isOpen ? 'block' : 'none'}>
        <FailedContainer isError={isError} show={isOpen ? 'block' : 'none'}>
          <WarningHead>
            <ToastHead>{heading}</ToastHead>
            <h2 onClick={() => toast.closeResponse()}>x</h2>
          </WarningHead>
          {messages.map((message, i) => (
            <div key={i}>
              <h5>{message}</h5>
              <br />
            </div>
          ))}
        </FailedContainer>
      </WrapperHolder>
    );
  } else {
    return null;
  }
}
