import { WHYTRIGGERBG } from 'assets/assets';
import BrandColors from 'globalStyles/utils/colors';
import media from 'globalStyles/utils/media';
import styled from 'styled-components/macro';

export const WhyTriggerContainer = styled.div`
  display: flex;
  background-image: url(${WHYTRIGGERBG});
  flex-direction: column;
  width: 100%;
  background-color: black;
  background-size: auto;
  color: white;
  padding: 50px 20px;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    /* margin-top: 1200px; */
  `}
`;

export const WhyHeader = styled.div`
  width: 490px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  span {
    color: ${BrandColors.main};
  }

  ${media.tablet`
    width: 100%;
  `}
`;

export const HeaderTextDiv = styled.div`
  width: fit-content;
  padding: 12px;
  align-self: center;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;

  ${media.tablet`
    font-size: 24px;
    line-height: 42px;
  `}
`;

export const TextArea = styled.p`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-top: 24px;
`;

export const ImageArea = styled.div`
  margin-left: 6%;
  img {
    margin-top: 52px;
    ${media.tablet`
    width: 100%;
    margin-top: 42px;
  `}
  }

  ${media.tablet`
    width: 100%;
  `}

  div {
    cursor: pointer;

    img {
      margin-bottom: 0px;

      ${media.tablet`
        width: 60px;
      `}
    }
  }
`;

export const ReasonLine = styled.div`
  border-top: 2px solid #44a4be;
  width: 100%;
  height: 2px;
`;

export const WhiteReasonLine = styled.div`
  border-top: 2px solid white;
  width: 80px;
  height: 2px;
  margin: 0 auto;
  margin-top: 34px;
`;

export const ReasonHeaderText = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  margin-top: 38px;
  color: #ffffff;

  ${media.tablet`
    font-weight: 600;
    font-size: 20px;
    margin-top: 10px;
  `}
`;

export const ReasonHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReasonBodyArea = styled.div`
  padding: 51px;
  background: #16191c;
  border-radius: 20px;
  min-height: 200px;
  max-width: 1040px;
  margin-top: 50px;
  margin-bottom: 100px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    color: #ffffff;

    ${media.tablet`
        font-size: 16px;
      `}
  }

  ${media.tablet`
      padding: 24px;
    `}
`;
