import React, { createContext, useContext, useState } from "react";

export const AppContext = createContext();

export default function AppProvider (props){

  const obj = {
    sideMenu: false
  }

  const [appObj, setAppObj] = useState(obj);

  return (
    <AppContext.Provider value={{ appObj, setAppObj }}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = ()=> useContext(AppContext)

