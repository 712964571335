import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedDiv = styled.div`
  opacity: 0;
  transform: translateY(20px);
  animation: ${({ animate }) =>
    animate &&
    css`
      ${fadeIn} 1s ease-in-out forwards;
    `};
`;

const ScrollAnimation = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  return (
    <AnimatedDiv ref={ref} animate={inView}>
      {children}
    </AnimatedDiv>
  );
};

export default ScrollAnimation;
