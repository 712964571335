export const Menu = [
  {
    label: 'About',
    id: "about"
  },
  {
    label: 'Features',
    id: "features"
  },
  {
    label: 'Why TriggerApp',
    id: "why-trigger"
  },
  // {
  //   label: 'Blog',
  //   id : "blog"
  // },
]