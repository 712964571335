import { ABOUTBGIMAGE } from 'assets/assets';
import BrandColors from 'globalStyles/utils/colors';
import media from 'globalStyles/utils/media';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components/macro';

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95vh;
  max-height: 890px;
  background: url(${ABOUTBGIMAGE});
  background: url(${ABOUTBGIMAGE}) 600w, url(${ABOUTBGIMAGE}) 1024w,
    url(${ABOUTBGIMAGE}) 1440w;
  background-size: cover;
  background-size: cover;
  align-items: center;
  position: relative;

  ${media.tablet`
  height: 70vh;
  background-position: 70% 0%;
  `}
`;

export const AboutHeadGradient = styled.div`
  background: linear-gradient(223deg, rgba(0, 0, 0, 0) 26.54%, #fff 85.42%);
  height: 100vh;
  width: 100vw;

  ${media.tablet`
  display: none;
  `}
`;

export const LineDiv = styled.div`
  border-left: 3px solid ${BrandColors.main};
  height: 78px;
  width: 10px;
  margin-top: 30px;

  ${media.tablet`
  `}
`;

export const AboutWordsArea = styled.div`
  padding-left: 15%;
  padding-top: 15%;

  h1 {
    width: 420px;
    color: #2e2e2e;
    font-size: 70.668px;
    font-style: normal;
    font-weight: 700;
    line-height: 74px;

    ${media.mobile`
    width: 100%;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
  `}
  }

  p {
    width: 500px;
    color: #737373;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.834px;
    margin-top: 30px;

    ${media.mobile`
    width: 100%;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `}
  }

  div {
    display: flex;
    align-items: center;
  }

  ${media.tablet`
  padding: 24px;
  `}
`;

export const AboutBottom = styled.div`
  display: flex;
  position: absolute;
  bottom: -100px;
  width: 80%;
  justify-content: space-around;
  /* margin-top: -40px; */

  ${media.tablet`
  display: none;
  `}
`;

export const MobileAboutBottom = styled.div`
  display: none;

  ${media.tablet`
  display: flex;
  flex-direction: column;
  margin-top: 70vh;
  background-color: white;
  align-items: center;
  `}
`;

export const MobileAboutHeadGradient = styled.div`
  display: flex;
  height: 80px;
  width: 100vw;
  background-color: red;
  background: linear-gradient(60deg, rgba(255, 255, 255, 0.8) 66.54%, #fff 85.42%);
`;

export const AboutOption = styled.div`
  border-radius: 7.156px;
  border: 0.523px solid #60b7e2;
  background: #fff;
  width: 174px;
  height: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 12.523px 29.072px 0px rgba(88, 91, 99, 0.16);
  padding: 16px 12px;

  h3 {
    color: #2e2e2e;
    text-align: center;
    font-size: 18.468px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.046px; /* 116.667% */
    letter-spacing: -0.215px;
    margin-top: 12px;
  }

  img {
  }
  ${media.tablet`
  width: 80%;
  max-width: 220px;
  margin-top: 54px;
  `}
`;

export const MobileTextArea = styled.div`
  display: none;

  ${media.tablet`
  display: block;
  width: 100%;
  padding: 24px;

  h2{
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: -25px;
  }

  button{
    height: 50px;
    width: 100%;
    border: none;
    color: white;
    border-radius: 8px;
    background-color: #60B7E2;
    cursor: pointer;
  }
  `}
`;
