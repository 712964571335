import BrandColors from 'globalStyles/utils/colors';
import media from 'globalStyles/utils/media';
import styled from 'styled-components/macro';

export const HeaderContainer = styled.div`
  display: flex;
  width: 100vw;
  /* position: fixed; */
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;

  a {
    text-decoration: none;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  /* max-width: 1440px; */
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
`;

export const HeaderDiv = styled.div`
  width: 100%;
  height: 100px;
  /* background-color: ${BrandColors.backgroundBlue}; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: absolute; */
  top: 0px;
  z-index: 1;
  padding-left: 170px;

  ${media.tablet`
  padding-left: 24px;
    /* padding-top: 20px; */
  `}
`;

export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;

  h2 {
    margin-left: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #2e2e2e;

    ${media.mobile`
      display: none;
    `}
  }
`;

export const MenuList = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin-right: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #2e2e2e;
    cursor: pointer;
  }

  ${media.tablet`
    display: none;
    margin-right: unset;
  `}
`;

export const MenuHolder = styled.div`
  display: flex;
  margin-right: 18%;
  ${media.tablet`
  margin-right: 0;
  `}
`;

export const SignupButton = styled.div`
  border: 1px solid ${BrandColors.lightBlue};
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 12px rgba(195, 209, 231, 0.4));
  border-radius: 4px;
  color: ${BrandColors.white};
  background-color: ${BrandColors.main};
  /* padding: 12px 24px; */
  height: 42px;
  /* width: 104px; */
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-weight: 600;

  img {
    width: 32px;
    margin-left: 4px;
  }

  ${media.tablet`
  margin-right: 0;
  `}
`;

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  margin-left: 8px;

  img {
    margin-right: 15px;
    cursor: pointer;
    display: none;

    ${media.tablet`
      display: flex;
    `}
  }
`;
