import BrandColors from "globalStyles/utils/colors";
import styled from "styled-components";

export const Container = styled.div`
	background-color: ${BrandColors.main};
	height: 100vh;
	width: 260px;
	padding: 1rem 2rem;
	display: none;

	@media only screen and (max-width: 600px) {
		display: block;
		transform: ${({ open }) => (open ? "translateX(0)" : "translateX(200%)")};
		position: fixed;
		top: 0;
		right: 0;
		z-index: 100;
		transition: all 0.5s linear;
	}
`;

export const Logo = styled.div`
	/* height: 6rem; */
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;

  p{
    font-weight: 600;
  }

  div{
    display: flex;
    align-items: center;
  }

	img {
		height: 30px;
		width: 30px;
    margin-right: 12px;
	}

	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	}
`;

export const SideItems = styled.div`
	margin-top: 2rem;
`;

export const Item = styled.div`
	display: flex;
	margin-top: 2rem;

	img {
		width: 1.3rem;
		margin-right: 2rem;
	}
`;

export const Paragraph = styled.div`
	color: white;
`;
