const BrandColors = {
	black: "#000000",
	gold: "#AB720A",
	main: "#44A4BE",
	white: "#ffffff",
	lightDark: "#2b2b2b",
	lightBlue: "#A6E0F0",
	lightGrey: "#C3D1E7",
	backgroundBlue: "#EFF4FA",
	grey: "#c4c4c4",
	darkGrey: "#878787",
    error: '#EB5757',
    underline: "#C3D1E7"
};

export default BrandColors;
