import { AppHolder, AppWrapper } from 'globalStyles/triggerStyle/style';
import React from 'react';
import About from './about/About';
import Features from './features';
import Footer from './footer/Footer';
import { HomepageContainer } from './style';
import WhyTrigger from './whyTrigger/WhyTrigger';

export default function Homepage() {
  return (
    <AppHolder>
      <About />
      <AppWrapper>
        <HomepageContainer>
          <Features />
        </HomepageContainer>
      </AppWrapper>
      <WhyTrigger />
      <Footer />
    </AppHolder>
  );
}
