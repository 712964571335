import media from 'globalStyles/utils/media';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled, { keyframes } from 'styled-components/macro';

export const FeatureList = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 200px;
  /* margin-top : 100px; */

  ${media.tablet`
  
    /* margin-top: 270px; */
  `}
`;

export const FeaturesDiv = styled.div`
  ${media.tablet`
    margin-top: 100px;
  `}
`;

export const FeaturesContainer = styled.div`
  /* padding: 24px; */
  display: flex;
  width: 100%;
  min-height: 851px;
  justify-content: space-around;
  background-color: black;

  ${media.tablet`
  background-repeat: no-repeat;
  background-size: auto;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding 50px 0px;
    /* padding-top: 40px; */
    /* margin-top: 270px; */
  `}
`;

// export const ImageArea = styled.div``;

export const FeaturesHeader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    width: 800px;
    text-align: center;

    ${media.tablet`
    width: 100%;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
  `}
  }

  p {
    width: 550px;
    text-align: center;
    margin-top: 32px;
    color: #808080;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    ${media.tablet`
    width: 100%;
    
  `}
  }

  ${media.tablet`
    
    margin-bottom: 50px;
    margin-top: 1060px;
    padding: 24px;
  `}
`;

export const FeaturesFlex = styled.div`
  display: flex;
  height: 500px;
  margin-top: 100px;
  width: 100%;

  ${media.tablet`
    flex-direction: column;
    margin-top: 0;
    height: unset;
  `}
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 68px;
  justify-content: center;

  h1 {
    color: #2e2e2e;
    font-size: 32.879px;
    font-style: normal;
    font-weight: 700;
    line-height: 36.335px;
    width: 300px;

    ${media.tablet`
    width: 100%;
    text-align: center;
    margin: 12px 0;
  `}
  }

  p {
    color: #808080;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 400px;
    margin-top: 12px;

    ${media.tablet`
    width: 100%;

  `}
  }

  img {
  }

  div {
  }

  ${media.tablet`
    width: 100%;
    order: 0;
    align-items: center;
    margin-left: 0;
    padding: 24px;
  `}
`;

export const IconHolder = styled.div`
  background-color: #e9f1f4;
  width: fit-content;
  padding: 8px;
  margin-bottom: 8px;
  ${media.tablet`
    
  `}
`;
export const SlideArea = styled.div`
  width: 50%;
  background-color: #e9f1f4;
  display: flex;
  ${media.tablet`
    
  `}
`;

export const generateRandomDelay = () => `${Math.random() * 3}s`; // Adjust the range as needed

const generateAnimationDelay = (delay) => `${delay}s`;

export const bounceUpDown = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8%);
  }
  60% {
    transform: translateY(-3%);
  }
`;

export const BouncingImage = styled.img`
  width: 50%;
  /* height: auto; */
  object-fit: contain;
  animation-duration: 15;
  animation: ${bounceUpDown} 15s infinite;
  animation-delay: ${(props) => generateAnimationDelay(props.bounceDelay)};

  ${media.tablet`
    min-width: 150px;
    width: 60%;
  `}

  ${media.mobile`
    min-width: 150px;
    width: 250px;
  `}
`;

export const PhonesArea = styled.div`
  display: flex;
  width: 55%;
  background-color: ${({ bg }) => bg};

  ${media.tablet`
  margin-top: 16px;
  padding-top: 32px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    order: 1;
  `}
`;
// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: slidesToShow,
//   slidesToScroll: 1,
//   centerMode: true,
//   centerPadding: '10px', // Adjust as needed
//   autoplay: autoSlide,
//   autoplaySpeed: 500,
//   // focusOnSelect: true,
//   draggable: true,
//   fade: true,
//   // responsive: [
//   //   {
//   //     breakpoint: 768,
//   //     settings: {
//   //       slidesToShow: 1,
//   //     },
//   //   },
//   //   // Add more breakpoints as needed
//   // ],
// };
