import React, { useEffect, useState } from 'react';
import { Menu } from './menu';
import {
  ButtonDiv,
  HeaderContainer,
  HeaderDiv,
  HeaderWrapper,
  LogoDiv,
  MenuHolder,
  MenuList,
  SignupButton,
} from './style';
import hamburger from 'assets/svgs/hamburger.svg';
import SideMenu from 'components/SideMenu';
import HeaderHelper from './helpers';
import { appUrl } from 'routes/client';
import { CICON, LOGO3 } from 'assets/assets';
import BrandColors from 'globalStyles/utils/colors';

export default function Header() {
  const [openMenu, setOpenMenu] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // Change background color when user scrolls down
        setScrolling(true);
      } else {
        // Reset background color when user scrolls back to the top
        setScrolling(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const backgroundColor = scrolling ? BrandColors.white : 'transparent';
  // const color = scrolling ? 'black' : 'white';
  const helper = new HeaderHelper();

  return (
    <HeaderContainer>
      <HeaderWrapper style={{ backgroundColor }}>
        <HeaderDiv>
          <SideMenu open={openMenu} setOpen={setOpenMenu} />
          <LogoDiv>
            <img src={LOGO3} alt="logo-" />
            <h2>TriggerApp</h2>
          </LogoDiv>
          <MenuHolder>
            <MenuList>
              {Menu.map((item, i) => (
                <h3 onClick={() => helper.scrollToMenu(item.id)} key={i}>
                  {item.label}
                </h3>
              ))}
            </MenuList>
            <a href={appUrl} rel="noreferrer" target="_blank">
              <SignupButton><p>JOIN BETA</p> <img src={CICON} alt="cIcon" /></SignupButton>
            </a>
            <ButtonDiv>
              <img
                onClick={() => helper.toggleSideMenu(openMenu, setOpenMenu)}
                src={hamburger}
                alt="ham"
              />
            </ButtonDiv>
          </MenuHolder>
        </HeaderDiv>
      </HeaderWrapper>
    </HeaderContainer>
  );
}
