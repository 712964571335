import BrandColors from 'globalStyles/utils/colors';
import Homepage from 'pages/home';
import NotFound from 'pages/notFound';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { homepageURL } from 'routes/client';
import { ThemeProvider } from 'styled-components';
// import { Questionnaire } from 'pages/questionnaire';
import Toast from 'components/toast/Toast';

function App() {
  return (
    <ThemeProvider theme={BrandColors}>
      <Toast />
      <Router>
        <Routes>
          <Route path={homepageURL} exact={true} element={<Homepage />} />
          {/* <Route path={joinURL} exact={true} element={<Join />} />
          <Route path={triviaURL} exact={true} element={<Questionnaire />} /> */}
          <Route path={`*`} exact={true} element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
