import logo from "assets/svgs/logo.svg"
import facebook from "assets/svgs/facebook.svg"
import instagram from "assets/svgs/instagram.svg"
import twitter from "assets/svgs/twitter.svg"

export const contactUs = {
  title: 'Contact Us',
  email: 'hello@mytriggerapp.com',
  question: 'Do you want to meet us. Let’s talk!',
  text: 'follow us',
  facebookLink: 'https://www.facebook.com/profile.php?id=61555141725807',
  facebookIcon: facebook,
  youTubeLink: '',
  youtubeIcon: '',
  twitterIcon: twitter,
  twitterLink: 'https://twitter.com/GetTriggerApp',
  instagramLink: 'https://www.instagram.com/triggerapp/',
  instagramIcon: instagram,
};

export const subscribeData = {
  logo: logo,
  text1: "Subscribe to our newsletter",
  text2: "The latest news, articles and resources and release news",
}